<template>
  <div class="ppt-detail-container full-screen">
    <div class="ppt-detail-main">
      <div class="ppt-detail-title">{{ data.zh_title }}</div>
      <PPTPlayer :data="data.courseware"></PPTPlayer>
      <div class="ppt-detail-score-block">
        <div class="ppt-detail-score-key">IF值</div>
        <div class="ppt-detail-score-value">
          <img :src="require('@/assets/img/triangle.png')" alt="" />
          {{ data.if_score }}
        </div>
      </div>
      <div class="ppt-detail-opera">
        <div class="ppt-detail-date">发布日期：{{ data.publish_date }}</div>
        <div class="ppt-detail-button-group">
          <img
            @click="handleLike"
            :src="
              require(`@/assets/img/${
                countInfo.is_collect ? 'liking' : 'like'
              }.png`)
            "
            alt=""
          />
          <img
            :src="require('@/assets/img/download.png')"
            alt=""
            @click="download"
          />
        </div>
      </div>
    </div>
    <IconButton
      :src="require('@/assets/img/cancel.png')"
      title="返回"
      @click="$router.back()"
    ></IconButton>
  </div>
</template>
<script>
import PPTPlayer from "@/components/player/PPTPlayer";
import IconButton from "@/components/unit/ButtonForIcon";
import weixin from "@/common/weixin";
export default {
  name: "ppt-detail",
  components: {
    PPTPlayer,
    IconButton,
  },
  data() {
    return {
      list: [
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_1.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_2.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_3.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_4.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_5.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_6.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_7.png",
        "https://kclass.imedunion.com/class/17/83/courseware/new_文献1：KEYNOTE 181 中帕博利珠单抗与化疗作为晚期食管癌二线治疗对健康相关生活质量的影响 -v5/images/2a09028a014416be8ca48c0f1aca8521_8.png",
      ],
      countInfo: {},
      data: {},
    };
  },
  created() {
    this.initData();
    this.initCountInfo();
  },
  methods: {
    initShare() {
      let params = {
        title: this.data.head_title,
        description: "",
        url: "",
        logo: this.$config.logo,
      };
      console.log("params :>> ", params);
      weixin.sharePageMessage(params);
    },
    initData() {
      let url = this.$tools.getURL(this.$urls.literature.detail, {
        id: this.$route.query.id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
          setTimeout(() => {
            this.initShare();
          }, 1000);
        })
        .catch(() => {});
    },
    initCountInfo() {
      let url = this.$tools.getURL(this.$urls.literature.countInfo, {
        id: this.$route.query.id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.countInfo = res.data;
        })
        .catch(() => {});
    },
    handleLike() {
      if (
        this.$store.state.common.userInfo.id ||
        this.$store.state.common.salesInfo.phone
      ) {
        let url = this.$tools.getURL(this.$urls.literature.collect, {
          id: this.$route.query.id,
          type: this.$store.state.common.userInfo.id
            ? 0
            : this.$store.state.common.salesInfo.phone
            ? 1
            : 0,
        });
        this.$axios
          .get(url)
          .then((res) => {
            console.log("res :>> ", res);
            this.$tips.success({ text: res.msg });
            this.countInfo.is_collect = this.countInfo.is_collect ? 0 : 1;
          })
          .catch(() => {});
      } else {
        this.$router.push({
          path: "/doctorLogin",
          query: {
            form: this.$route.path,
            id: this.$route.query.id,
          },
        });
      }
    },
    download() {
      window.location.href = this.data.ppt_url;
      this.$tips.success({ text: "下载成功" });
    },
  },
};
</script>
<style>
.ppt-detail-container {
  padding: 20px 21px;
  display: flex;
  flex-direction: column;
}
.ppt-detail-main {
  flex: 1 0 0;
  overflow: auto;
}
.ppt-detail-title {
  font-weight: bold;
  font-size: 14px;
  color: #1aa6ec;
  line-height: 36px;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 37px;
  text-align: center;
}
.ppt-detail-score-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.ppt-detail-score-key {
  font-weight: 500;
  font-size: 21px;
  color: #606060;
  line-height: 35px;
}
.ppt-detail-score-value {
  font-weight: 800;
  font-size: 42px;
  color: #1aa6ec;
  line-height: 35px;
  display: flex;
  align-items: center;
}
.ppt-detail-score-value img {
  width: 18px;
  height: auto;
  margin-right: 10px;
}
.ppt-detail-opera {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.ppt-detail-date {
  font-weight: 500;
  font-size: 14px;
  color: #606060;
  line-height: 35px;
}
.ppt-detail-button-group {
  display: flex;
  align-items: center;
}
.ppt-detail-button-group img:first-child {
  margin-right: 43px;
}
.ppt-detail-button-group img {
  height: 22px;
  width: auto;
}
</style>